import React, { useContext, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components";
import { Popover, AnchorButton, Position, Divider, MenuItem, Menu, MenuDivider, IconName } from "@blueprintjs/core";
import md5 from "md5";
import { Route, Switch, useRouteMatch, NavLink, Redirect } from "react-router-dom";
// import { UserContext } from "../data-contexts/user-context";
import Vehicles from "./Vehicles/Vehicles";
import Vehicle from "./Vehicles/Vehicle";
import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import { useAuth } from "oidc-react";
import Playground from "./Playground";

let setDebugGolbal: any = () => {};

function App() {
  const [debug, setDebug] = useState("Empty");
  const user = useAuth();

  console.log({ user });

  // const userContext = useContext(UserContext);
  const gravatarMD5Hash = useMemo(() => {
    const email = user.userData?.profile.email;
    if (!email) return;
    return md5(email);
  }, [user.userData?.profile.email]);

  setDebugGolbal = setDebug;

  const UserMenu = (
    <Menu>
      <MenuItem text="Bjud in vänner" icon="envelope" />
      <MenuItem text="Inställningar" icon="cog" />
      <MenuItem text="Kopplingar" icon="key" />
      <MenuItem text="Mitt konto" icon="credit-card" />
      <MenuDivider />
      <MenuItem text="Logga ut" icon="log-out" />
    </Menu>
  );

  const NavItem = (text: string, icon: IconName, href: string) => {
    return (
      <NavLink to={href}>
        <AnchorButton active={useRouteMatch(href)?.isExact || false} icon={icon} large intent="primary" minimal>
          {text}
        </AnchorButton>
      </NavLink>
    );
  };

  return (
    <Main>
      {/* {userContext.loginStatus === "LOGGED_OUT" && <Redirect to="/login" />} */}
      <Topbar>
        <Popover content={UserMenu} position={Position.BOTTOM_LEFT}>
          <UserNav>
            <img src={`https://www.gravatar.com/avatar/${gravatarMD5Hash}`} />
            <label>{user.userData?.profile.name}</label>
          </UserNav>
        </Popover>
      </Topbar>

      <Sidenav>
        <NavLink to="/">
          <Logo />
        </NavLink>
        <br />
        <Divider />
        <br />
        {NavItem("Mina fordon", "drive-time", "/services/my-vehicles")}
        {NavItem("Hushållet", "home", "/services/my-household")}
        {NavItem("Bostadsområdet", "map", "/services/my-area")}
        {NavItem("Min ekonomi", "bank-account", "/services/my-economy")}
        {NavItem("Vänner", "people", "/services/my-friends")}
        {NavItem("Kontoret", "office", "/services/my-office")}
        {NavItem("Händelser", "calendar", "/services/my-office")}
        <br />
        <Divider />
        <br />
        {NavItem("Fler tjänster", "cube", "/services")}
        <br />
        <Divider />
        <br />
        {NavItem("Bjud in vänner", "envelope", "/user/invites")}
        {NavItem("Inställningar", "cog", "/user/settings")}
        {NavItem("Kopplingar", "key", "/user/connections")}
        {NavItem("Mitt konto", "credit-card", "/user/account")}
        {NavItem("Om Botler", "help", "/about")}
      </Sidenav>

      <Content>
        <Switch>
          <Route path="/playground">
            <Playground />
          </Route>
          <Route path="/services/my-household">HSB!!</Route>
          <Route path="/services/my-vehicles/:licensePlate">
            <Vehicle />
          </Route>
          <Route path="/services/my-vehicles" exact={true}>
            <Vehicles />
          </Route>
        </Switch>
        <hr />
        <pre
          style={{
            width: "600px",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        ></pre>
      </Content>
    </Main>
  );
}

const Main = styled.div`
  display: grid;
  grid-template-rows: 3rem auto;
  grid-template-columns: 10rem auto;
  grid-template-areas:
    "sidenav  topbar"
    "sidenav  content";
  min-height: 100vh;
  background: rgb(243, 243, 243);
  width: 900px;
  margin: 0px auto;
`;

const Topbar = styled.div`
  grid-area: topbar;
  display: flex;
  justify-content: flex-end;
`;

const Sidenav = styled.div`
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: stretch;

  a {
    width: 100%;
  }
`;

const Content = styled.div`
  grid-area: content;
`;

const Logo = styled.div`
  background-image: url("/img/logo-trans.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 13rem;
`;

const UserNav = styled.div`
  margin: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  label {
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 0.8rem;
  }
`;

export default App;
