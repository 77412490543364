import CarMap from "./Vehicles/CarMap";

export default function Playground() {
  const tripIds = [
    "6e7c2e0d-05e1-4ee1-a2fd-bed97d78960d",
    "b26f91bd-470b-4df8-bc8e-b97266915850",
    "e109ce71-aa83-4544-92b5-b6d609b8279d",
    "58044897-0d8f-4e05-a254-762053549e57",
    "9ba3b746-dbc6-477a-809f-8b3754b14067",
    "35fd1a9b-7a72-4ed8-adcf-64429fee3b98",
    "97ea2c77-fb34-4708-9150-5d2bca50bf9d",
    "e0ffb82c-b919-46f7-91d1-25266b86be1c",
    "64a834d3-e533-4efc-8bed-5da931e91089",
    "ad694881-1688-45bd-92a5-5ae903132399",
    "6acbdc33-e626-4f3a-b374-a2c09c50ac12",
  ];

  return (
    <div>
      <CarMap licensePlate="BUP24R" tripIds={tripIds} />
    </div>
  );
}
