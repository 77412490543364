import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Callout, Card, Classes, Dialog, Divider, Elevation, H4, Icon, InputGroup, Label, MenuItem, ProgressBar, Spinner, Tag, TextArea } from "@blueprintjs/core";
import { Cell, Column, Table2 } from "@blueprintjs/table";
import { HotkeysProvider } from "@blueprintjs/core";
import * as turf from "@turf/turf";
import L from "leaflet";
import { GeoJSON, MapContainer, Popup, TileLayer, Tooltip } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import { useParams } from "react-router";
import { Select } from "@blueprintjs/select";
import { useState } from "react";
import { DatePicker, DateRangePicker } from "@blueprintjs/datetime";
import MomentLocaleUtils from "react-day-picker/moment";
import { v4 as uuidv4 } from "uuid";
import "moment/locale/sv";
import CarMap from "./CarMap";

export default function Vehicle(props: any) {
  const { licensePlate } = useParams<{ licensePlate: string }>();
  const { loading, error, data } = useQuery(
    gql`
    {
      Vehicle(where: { licensePlate: { _eq: "${licensePlate}" } }) {
        brandName
        fuelType
        licensePlate
        id
        RentalBusiness {
          id
          name
          RentalBusinessMembers {
            id
            isOwner
            isAdmin
            isMember
            User {
              id
              name
            }
          }
          RentalPeriods(order_by: { bookingStartedAt: desc }) {
            id
            driverId
            bookingStartedAt
            bookingEndedAt
            description
            Trips {
              # route
              id
              startedAt
              endedAt
            }
            Trips_aggregate {
              aggregate {
                min {
                  totalStartKm
                }
                max {
                  totalEndKm
                }
              }
            }
            RentalBusinessMember {
              User {
                name
              }
            }
          }
        }
        name
        modelName
        imageUrl
        Parkings(order_by: { startAt: desc }, limit: 5) {
          costAmount
          costCurrency
          startAt
          status
          stopAt
          Parkinglot {
            city
            location
            name
            owner
            provider
            street
            zoneCode
          }
        }
        VehicleStatuses(limit: 1, order_by: { locationAt: desc }) {
          location
          locationAt
          totalTravelKm
          hvacTempInside
          hvacMode
          evDistanceKm
          gasDistanceAcKm
          gasFullnessPercent
          hvacTempSetting
          evChargeCablestatus
          evChargeFullnessPercent
          evChargeRemainingMin
          evChargeStatus
          evDistanceinclacKm
          hvacBlower
          hvacDefrostFront
          hvacDefrostRear
          hvacLatestActiveAt
          statsAt
        }
        Trips_aggregate {
          aggregate {
            sum {
              statFuelConsumptionInL
              statOverspeedDistanceInKm
              statOverspeedDurationInSec
              statTotalDistanceInKm
              statTotalDurationInSec
              statHighwayDistanceInKm
              statHighwayDurationInSec
              statIdleDurationInSec
            }
            max {
              statMaxSpeedInKmph
              statTotalDistanceInKm
              statTotalDurationInSec
            }
            avg {
              statAverageSpeedInKmph
              statTotalDistanceInKm
              statTotalDurationInSec
              statHighwayDistanceInKm
              statHighwayDurationInSec
              statFuelConsumptionInL
            }
            stddev {
              statAverageSpeedInKmph
            }
            count
          }
        }
        Trips(limit: 150, order_by: { endedAt: desc }) {
          id
          route
          endedAt
          driverId
          startedAt
          totalEndKm
          totalStartKm
          statTotalDistanceInKm
          carRentalId
        }
      }
    }
  `,
    { pollInterval: 3000 }
  );

  const [updateTripRentalPeriod] = useMutation(gql`
    mutation ($tripId: uuid!, $carRentalId: uuid) {
      update_Trip_by_pk(pk_columns: { id: $tripId }, _set: { carRentalId: $carRentalId }) {
        id
      }
    }
  `);

  const assignTripToRental = async (trip, rentalPeriod) => {
    console.log("Assign", trip, rentalPeriod);
    await updateTripRentalPeriod({
      variables: {
        tripId: trip.id,
        carRentalId: rentalPeriod?.id || null,
      },
    });
  };

  return (
    <div>
      {loading && <ProgressBar />}
      {data &&
        data.Vehicle.map((car: any) => {
          const stats = car?.VehicleStatuses[0];
          const tripsAgg = car?.Trips_aggregate.aggregate;

          const gasCapacityLiters = 55.0;
          const gasLitersPerKm = 0.0785;
          const gasTotalConsumedLiters = tripsAgg.sum.statFuelConsumptionInL;
          const gasTotalTravelKm = gasTotalConsumedLiters / gasLitersPerKm;
          const batteryCapacityKwh = 18.1;
          const batteryKwhPerKm = 0.218;
          const batteryTotalTraveledKm = stats.totalTravelKm - gasTotalTravelKm;
          const batteryTotalConsumedKwh = batteryTotalTraveledKm * batteryKwhPerKm;

          const costPerEvKwh = 1.75;
          const costPerGasLiter = 20;

          const loc = stats?.location;
          const carPosition = L.GeoJSON.coordsToLatLng(loc?.coordinates || [0, 0]);
          const carIcon = <img src={car.imageUrl} style={{ width: "64px", height: "32px" }} />;
          const parkingIcon = (
            <div
              style={{
                width: "32px",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                background: "darkblue",
                border: "2px solid white",
                borderRadius: "5px",
                margin: "auto auto",
                padding: "0px",
              }}
            >
              P
            </div>
          );

          return (
            <>
              <br />
              <Card elevation={Elevation.ONE}>
                <div style={{ display: "grid", gridTemplateColumns: "40fr 60fr" }}>
                  <div>
                    <strong>
                      {car.brandName}, {car.modelName}
                    </strong>
                    <br />
                    {car.name !== car.modelName && <strong>{car.name || <em>No name</em>}</strong>}
                    <br />
                    <strong>Reg. nummer</strong>
                    <br />
                    <em>{car.licensePlate}</em>
                    <br />
                    <br />
                    <strong>Bränsletyp(er)</strong>
                    <br />
                    <em>{car.fuelType}</em>
                    <br />
                    <br />
                    <strong>Batteri</strong>
                    <br />
                    <em>
                      {batteryCapacityKwh} kWh ({Math.round(batteryCapacityKwh / batteryKwhPerKm)} km)
                    </em>
                    <br />
                    <em>Ca. {batteryKwhPerKm * 10} kWh per 10 km</em>
                    <br />
                    <br />
                    <strong>Bensin</strong>
                    <br />
                    <em>
                      {gasCapacityLiters} liter ({Math.round(gasCapacityLiters / gasLitersPerKm)} km)
                    </em>
                    <br />
                    <em>Ca. {gasLitersPerKm * 10} liter per 10 km</em>
                    <br />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>{car.imageUrl && <img style={{ width: "100%" }} src={car.imageUrl} />}</div>
                </div>
                <br />
                {
                  <div>
                    <Divider />
                    {/* Current stats */}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
                      {/* Battery */}
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h4>Batteri 🔋</h4>
                        <Spinner size={120} intent="success" value={stats.evChargeFullnessPercent / 100.0} />
                        <br />
                        <span>{Math.round(stats.evDistanceinclacKm)} km kvar</span>
                        {["charging"].includes(stats.evChargeStatus) && <em>{stats.evChargeRemainingMin} min till fulladdat</em>}
                        {["none", "chargeComplete"].includes(stats.evChargeStatus) && <em>Laddar inte just nu</em>}
                      </div>

                      {/* Gas */}
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h4>Bensin 💦</h4>
                        <Spinner size={120} intent="warning" value={stats.gasFullnessPercent / 100.0} />
                        <br />
                        <span>{Math.round(stats.gasDistanceAcKm)} km kvar</span>
                        {<em>{stats.gasFullnessPercent}% tankad</em>}
                      </div>

                      {/* Temp / HVAC */}
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
                        <br />
                        <strong>Temperatur</strong>
                        <Tag large={true}>{stats.hvacTempInside} ºC</Tag>
                        <br />
                        <strong>A/C</strong>
                        <Tag large={true} rightIcon={stats.hvacMode === 0 ? "disable" : undefined}>
                          {stats.hvacTempSetting} ºC
                        </Tag>
                        <br />
                        <br />
                      </div>
                    </div>
                    <br />

                    <Divider />
                    {/*Total statistics */}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                      {/* Consumption */}
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <br />
                        <strong>Totalt resande</strong>
                        <Tag>{stats.totalTravelKm} km</Tag>
                        <Divider />
                        <Tag>
                          {Math.floor(tripsAgg.sum.statTotalDurationInSec / 3600)} h {Math.round(tripsAgg.sum.statTotalDurationInSec % 60)} min
                        </Tag>
                        <br />
                        <strong>Batteri anv. 🔋</strong>
                        <Tag intent="success" large={true}>
                          {Math.round(batteryTotalConsumedKwh)} kWh
                        </Tag>
                        <Divider />
                        <Tag intent="success">{Math.round(batteryTotalTraveledKm)} km</Tag>
                        <br />
                        <strong>Bensin förbr. 💦</strong>
                        <Tag intent="warning" large={true}>
                          {Math.round(gasTotalConsumedLiters)} liter
                        </Tag>
                        <Divider />
                        <Tag intent="warning">{Math.round(gasTotalTravelKm)} km</Tag>
                      </div>

                      {/* Gas */}
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Callout icon="heart" intent="success">
                          <H4>
                            Förminskat CO<sub>2</sub>-utsläpp
                          </H4>
                          Du har reducerat ditt klimatavtryck med <Tag>{Math.round(batteryTotalTraveledKm * gasLitersPerKm * 2.99)} kg</Tag> CO<sub>2</sub>-utsläpp, beräknat utifrån klimatkompenserad el-laddning.
                          <br />
                          <br />
                          Detta kommer ifrån att du undvikit att tanka {Math.round(batteryTotalTraveledKm * gasLitersPerKm)} liter bensin, tack vare batteriladdning ifrån el-stolpe och regenerativ bromsning.
                          <br />
                          <br />
                          Du snittar <Tag>{Math.round((100 * batteryTotalTraveledKm) / stats.totalTravelKm)}%</Tag> på batteri, vilket totalt sett har sparat dig{" "}
                          <Tag>{Math.round(batteryTotalTraveledKm * gasLitersPerKm * costPerGasLiter - batteryTotalConsumedKwh * costPerEvKwh)} kr</Tag>. Besparingen är pga du laddat för{" "}
                          {Math.round(batteryTotalConsumedKwh * costPerEvKwh)} kr istället för att tanka {Math.round(batteryTotalTraveledKm * gasLitersPerKm * costPerGasLiter)} kr (beräknat utifrån {costPerEvKwh} kr/kWh och{" "}
                          {costPerGasLiter} kr/l bensin).
                        </Callout>
                      </div>
                    </div>

                    <Divider />
                    {/*Trip statistics */}
                    <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Callout>
                          Bilen har använts till <Tag>{tripsAgg.count}</Tag> resor.
                          <br />
                          <br />
                          För dessa resor så är <Tag>{Math.round((100 * tripsAgg.avg.statHighwayDistanceInKm) / tripsAgg.avg.statTotalDistanceInKm)} %</Tag> av distansen på motorväg, vilket motsvarar{" "}
                          <Tag>{Math.round((100 * tripsAgg.avg.statHighwayDurationInSec) / tripsAgg.avg.statTotalDurationInSec)} %</Tag> av körtiden.
                          <br />
                          <br />
                          Totalt sett har <Tag>{Math.round(tripsAgg.sum.statOverspeedDurationInSec / 60)} min</Tag> (dvs.{" "}
                          <Tag>{Math.round((1000 * tripsAgg.sum.statOverspeedDurationInSec) / tripsAgg.sum.statTotalDurationInSec) / 10.0} %</Tag>) av allt körande varit över hastighetsbegränsningen.
                        </Callout>
                      </div>

                      {/* Consumption */}
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <br />
                        <strong>Typisk resa</strong>
                        <Tag>{Math.round(tripsAgg.avg.statTotalDistanceInKm * 100) / 100.0} km</Tag>
                        <Divider />
                        <Tag>{Math.round(tripsAgg.avg.statTotalDurationInSec / 60)} min</Tag>
                        <br />
                        <strong>Snitthastighet 📊</strong>
                        <Tag large={true}>{Math.round(tripsAgg.avg.statAverageSpeedInKmph)} km/h</Tag>
                        <Tag>&plusmn; {Math.round(tripsAgg.stddev.statAverageSpeedInKmph)} km/h</Tag>
                        <br />
                        <strong>Rekord 🚀</strong>
                        <Tag large={true}>{Math.round(tripsAgg.max.statTotalDistanceInKm)} km</Tag>
                        <Divider />
                        <Tag>
                          {Math.floor(tripsAgg.max.statTotalDurationInSec / 3600)} h {Math.round((tripsAgg.max.statTotalDurationInSec / 60) % 60)} min
                        </Tag>
                        <Divider />
                        <Tag>{Math.round(tripsAgg.max.statMaxSpeedInKmph)} km/h 🙊</Tag>
                      </div>
                    </div>
                    <br />

                    <small style={{ color: "#ccc", fontStyle: "italic", float: "right", clear: "both" }}>Senast uppdaterad för {Math.round((Date.now() - new Date(stats.statsAt).getTime()) / (60 * 1000))} minuter sedan</small>
                    <br />
                    <Divider />
                    <MapContainer center={carPosition} zoom={16} scrollWheelZoom={true} style={{ height: "800px" }}>
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <Marker position={carPosition} icon={carIcon}>
                        <Tooltip>{JSON.stringify(carPosition)}</Tooltip>
                      </Marker>
                      {car?.Trips?.map((trip: any) => {
                        return <GeoJSON key={trip.id} data={trip.route} />;
                      })}
                      {car?.Parkings?.map((parking: any) => {
                        const parkingPos = L.GeoJSON.coordsToLatLng(turf.center(turf.points(parking?.Parkinglot?.location?.coordinates[0])).geometry.coordinates as any);
                        console.log({ parkingPos });

                        return (
                          <GeoJSON key={parking.id} data={parking.Parkinglot.location}>
                            <Marker position={parkingPos} icon={parkingIcon}>
                              <Tooltip>{JSON.stringify(parkingPos)}</Tooltip>
                            </Marker>
                            <Popup>
                              [{parking.startAt}] {parking?.Parkinglot.name} - {parking.costAmount} {parking.costCurrency}
                            </Popup>
                          </GeoJSON>
                        );
                      })}
                    </MapContainer>
                  </div>
                }

                {car?.RentalBusiness && <Rentals rb={car.RentalBusiness} />}

                <br />
                <br />
                <h2>Senaste 15 resorna</h2>
                {/* <HotkeysProvider>
                  <Table2 numRows={car.Trips.length}>
                    <Column name="Datum" cellRenderer={(i: number) => <Cell>{new Date(car.Trips[i].startedAt).toLocaleDateString("sv")}</Cell>} />
                    <Column name="Från" cellRenderer={(i: number) => <Cell>{new Date(car.Trips[i].startedAt).toLocaleTimeString("sv")}</Cell>} />
                    <Column name="Till" cellRenderer={(i: number) => <Cell>{new Date(car.Trips[i].endedAt).toLocaleTimeString("sv")}</Cell>} />
                  </Table2>
                </HotkeysProvider> */}
                <table className="bp3-html-table .modifier">
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>
                        Tid <br />
                        start
                      </th>
                      <th>
                        -||-
                        <br /> slut
                      </th>
                      <th>Distans [km]</th>
                      <th>
                        Mätarställning
                        <br /> start [km]
                      </th>
                      <th>
                        -||-
                        <br /> slut [km]
                      </th>
                      <th>Hyrning</th>
                    </tr>
                  </thead>
                  <tbody>
                    {car.Trips?.map((t) => {
                      const assignedRentalPeriod = car?.RentalBusiness?.RentalPeriods.find((rp) => rp.id === t.carRentalId);

                      return (
                        <tr>
                          <td>{new Date(t.startedAt + "Z").toLocaleDateString("sv")}</td>
                          <td>{new Date(t.startedAt + "Z").toLocaleTimeString("sv")}</td>
                          <td>{new Date(t.endedAt + "Z").toLocaleTimeString("sv")}</td>
                          <td>{t.statTotalDistanceInKm}</td>
                          <td>
                            {Math.round(
                              car.Trips.filter((t2) => new Date(t2.endedAt) >= new Date(t.endedAt)).reduce((m, t) => {
                                return m - t.statTotalDistanceInKm;
                              }, car.VehicleStatuses[0]?.totalTravelKm)
                            )}
                          </td>
                          <td>
                            {Math.round(
                              car.Trips.filter((t2) => new Date(t2.endedAt) > new Date(t.endedAt)).reduce((m, t) => {
                                return m - t.statTotalDistanceInKm;
                              }, car.VehicleStatuses[0]?.totalTravelKm)
                            )}
                          </td>
                          <td>
                            {(() => {
                              const RentSelect = Select.ofType<any>();
                              const RentalAssignSelectionRenderer = (rp, { handleClick }) => {
                                if (rp === null) {
                                  return (
                                    <div>
                                      <Button onClick={handleClick} icon="cross">
                                        Ej hyresresa
                                      </Button>
                                      <br />
                                      <br />
                                    </div>
                                  );
                                }
                                const rbm = car?.RentalBusiness?.RentalBusinessMembers.find((rbm: any) => rbm.id === rp.driverId);
                                const startAt = new Date(rp.bookingStartedAt + "Z");
                                const endAt = new Date(rp.bookingEndedAt + "Z");
                                const isAssignedToRental = t.carRentalId === rp.id;
                                return (
                                  <div>
                                    <Button onClick={handleClick} disabled={isAssignedToRental} icon="calendar">
                                      {rbm.User.name}, <span title={startAt.toLocaleString("sv")}>{startAt.toLocaleDateString("sv")}</span> - <span title={endAt.toLocaleString("sv")}>{endAt.toLocaleDateString("sv")}</span>
                                      <br />
                                      <em>{rp.description}</em>
                                    </Button>
                                    <br />
                                    <br />
                                  </div>
                                );
                              };
                              const rentalSelections = [...(car?.RentalBusiness?.RentalPeriods || []), null];
                              return (
                                <RentSelect items={rentalSelections} filterable={false} itemRenderer={RentalAssignSelectionRenderer} onItemSelect={(rp) => assignTripToRental(t, rp)}>
                                  <Button small text={assignedRentalPeriod ? new Date(assignedRentalPeriod.bookingStartedAt + "Z").toLocaleDateString("sv") : ""} rightIcon="caret-down" />
                                </RentSelect>
                              );
                            })()}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <h4>Formulär ifylles vid hämtning och återlämning av hyresfordon</h4>
                <h4>Användare</h4>
                <InputGroup defaultValue="Johan Deckmar" />

                <br />

                <h4>Övrigt</h4>
                <TextArea defaultValue="" /> */}
              </Card>
              <br />
            </>
          );
        })}
      {/* <hr /> */}
      {/* <h3>Debug</h3> */}
      {/* <pre>{JSON.stringify({ loading, error, data }, null, 2)}</pre> */}
    </div>
  );
}

const Rentals = (props: any) => {
  const [dialogCreateOpen, setDialogCreateOpen] = useState(true);
  const [dialogCreateSelectedRenter, setDialogCreateSelectedRenter] = useState(null as any);
  const [dialogCreateSelectedStart, setDialogCreateSelectedStart] = useState(null as any);
  const [dialogCreateSelectedEnd, setDialogCreateSelectedEnd] = useState(null as any);
  const [dialogCreateDesc, setDialogCreateDesc] = useState(null as any);
  const [createRentalPeriod, { data: d2, loading: l2, error: e2 }] = useMutation(gql`
    mutation ($obj: RentalPeriod_insert_input!) {
      insert_RentalPeriod_one(object: $obj) {
        id
      }
    }
  `);

  const RenterSelect = Select.ofType<any>();
  const RenterListItem = (rbm, { handleClick }) => <MenuItem onClick={handleClick} icon="user" text={rbm?.User?.name} />;

  const [dialogShowRentalForm, setDialogShowRentalForm] = useState(null as any);

  const rb = props.rb;
  if (!rb) return null;

  const handleRentalPeriodAddClick = async () => {
    const period = await createRentalPeriod({
      variables: {
        obj: {
          id: uuidv4(),
          driverId: dialogCreateSelectedRenter.id,
          rentalBusinessId: rb.id,
          description: dialogCreateDesc || "",
          bookingStartedAt: dialogCreateSelectedStart.toISOString(),
          bookingEndedAt: dialogCreateSelectedEnd.toISOString(),
        },
      },
    });

    console.log("Rental period created", period);
  };

  return (
    <div>
      <br />
      <br />
      <h2>
        Senaste uthyrningarna ({rb.name}){" "}
        <Button onClick={() => setDialogCreateOpen(true)}>
          <Icon icon={"plus"} />
        </Button>
        <Dialog canEscapeKeyClose={true} canOutsideClickClose={true} onClose={() => setDialogCreateOpen(false)} isOpen={dialogCreateOpen} icon="calendar" title="Lägg till uthyrningsperiod">
          <div className={Classes.DIALOG_BODY}>
            <Label>
              <strong>Uthyrningskontrakt</strong>
              <p>{rb.name}</p>
            </Label>
            <br />
            <Label>
              <strong>Hyrare</strong>
              <RenterSelect
                filterable={false}
                items={rb.RentalBusinessMembers}
                itemRenderer={RenterListItem}
                onItemSelect={(rbm) => {
                  console.log(rbm);
                  setDialogCreateSelectedRenter(rbm);
                }}
              >
                <Button text={dialogCreateSelectedRenter?.User?.name || "Välj.."} rightIcon="caret-down" icon="person" />
              </RenterSelect>
            </Label>
            <br />
            <Label>
              <strong>Period</strong>
            </Label>
            <DateRangePicker
              locale="sv"
              localeUtils={MomentLocaleUtils}
              allowSingleDayRange={true}
              highlightCurrentDay={true}
              shortcuts={false}
              singleMonthOnly={true}
              timePrecision="minute"
              reverseMonthAndYearMenus={true}
              timePickerProps={{ useAmPm: false, showArrowButtons: true }}
              onChange={(range) => {
                setDialogCreateSelectedStart(range[0] || null);
                setDialogCreateSelectedEnd(range[1] || null);
              }}
            />
            <br />
            <div style={{ width: "400px", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
              <Tag icon="calendar" large>
                {dialogCreateSelectedStart?.toLocaleString("sv")}
              </Tag>
              <Icon icon="arrow-right" />
              <Tag icon="calendar" large>
                {dialogCreateSelectedEnd?.toLocaleString("sv")}
              </Tag>
            </div>
            <br />
            <br />
            <Label>
              <strong>Beskrivning (valfritt)</strong>
              <InputGroup onChange={(e) => setDialogCreateDesc(e.target.value)} placeholder="Hyrningens syfte / Minnesanteckning.."></InputGroup>
            </Label>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button disabled={!dialogCreateSelectedStart || !dialogCreateSelectedEnd || !dialogCreateSelectedRenter} icon={"plus"} onClick={handleRentalPeriodAddClick} intent="primary" text="Lägg till" />
            </div>
          </div>
        </Dialog>
      </h2>

      {rb.RentalPeriods?.map((rp) => {
        const rbm = rb.RentalBusinessMembers.find((rbm: any) => rbm.id === rp.driverId);
        const startAt = new Date(rp.bookingStartedAt + "Z");
        const endAt = new Date(rp.bookingEndedAt + "Z");

        return (
          <Card elevation={1} interactive style={{ margin: "1rem 0px" }} onClick={() => setDialogShowRentalForm(rp)}>
            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 0.2fr 1fr", gap: "4px", alignItems: "center" }}>
              <Tag icon="person" large>
                {rbm.User.name}
              </Tag>
              <br />
              <Tag icon="calendar" minimal>
                {new Date(rp.bookingStartedAt).toLocaleString("sv")}
              </Tag>
              &nbsp;&mdash;&nbsp;
              <Tag icon="calendar" minimal>
                {new Date(rp.bookingEndedAt).toLocaleString("sv")}
              </Tag>
            </div>

            <br />
            <br />

            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div>
                <strong>Mätarställning</strong>
                <br />
                <Tag>{Math.floor(rp.Trips_aggregate.aggregate.min.totalStartKm || 0)} km</Tag>&nbsp;&mdash;&nbsp;<Tag>{Math.ceil(rp.Trips_aggregate.aggregate.max.totalEndKm || 0)} km</Tag>
                <br />
                <br />
                <strong>Körd sträcka</strong>
                <br />
                <Tag>{Math.ceil(rp.Trips_aggregate.aggregate.max.totalEndKm - rp.Trips_aggregate.aggregate.min.totalStartKm)} km</Tag>
                <br />
                <br />
                {/* <TripsMap licensePlate={licensePlate} tripIds={[]} /> */}
              </div>
              <div>
                <strong>Syfte / Minnesanteckning</strong>
                <br />
                <Tag minimal large>
                  {rp.description}
                </Tag>
              </div>
            </div>
            <Dialog
              style={{ width: "800px" }}
              icon="form"
              canEscapeKeyClose={true}
              canOutsideClickClose={true}
              onClose={() => setDialogShowRentalForm(null)}
              isOpen={dialogShowRentalForm === rp}
              title="Formulär ifylles vid hämtning och återlämning av hyresfordon."
            >
              <div className={`${Classes.DIALOG_BODY} rentalForm`} style={{ fontSize: "12pt" }}>
                <h4>Användare</h4>
                <InputGroup value={rp?.RentalBusinessMember.User?.name} />

                <br />

                <div style={{ width: "400px" }}>
                  <h2>Hämtat</h2>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "300px" }}>
                    <h4>Datum</h4>
                    <h4>Kl</h4>
                    <InputGroup value={new Date(rp?.bookingStartedAt + "Z").toLocaleDateString("sv")} />
                    <InputGroup value={new Date(rp?.bookingStartedAt + "Z").toLocaleTimeString("sv")} />
                  </div>

                  <h4>Mätarställning vid hämtning</h4>
                  <InputGroup value={"" + Math.floor(rp.Trips_aggregate.aggregate.min.totalStartKm) + " km"} />

                  <br />

                  <h4 style={{ marginBottom: "0px" }}>Tankat vid hämtning, mer info se informationsblad</h4>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "300px" }}>
                    <h4>Liter</h4>
                    <h4>SEK</h4>
                    <InputGroup value={""} />
                    <InputGroup value={""} />
                  </div>

                  <br />
                  <h2>Lämnat</h2>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "300px" }}>
                    <h4>Datum</h4>
                    <h4>Kl</h4>
                    <InputGroup value={new Date(rp?.bookingEndedAt + "Z").toLocaleDateString("sv")} />
                    <InputGroup value={new Date(rp?.bookingEndedAt + "Z").toLocaleTimeString("sv")} />
                  </div>
                  <h4>Mätarställning vid lämning</h4>
                  <InputGroup value={"" + Math.ceil(rp.Trips_aggregate.aggregate.max.totalEndKm) + " km"} />

                  <br />
                  <h4>Parkeringsböter och infrastruktursavgifter</h4>
                  <InputGroup value={""} />
                </div>

                <br />

                <h4>Övrigt</h4>
                <TextArea style={{ width: "100%" }} />
              </div>
            </Dialog>
          </Card>
        );
      })}
    </div>
  );
};
