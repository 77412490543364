import { gql, useQuery } from "@apollo/client";
import { Button, Callout, Card, Checkbox, DialogStep, Elevation, FormGroup, InputGroup, MultistepDialog, ProgressBar, Spinner } from "@blueprintjs/core";
import L from "leaflet";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Vehicles(props: any) {
  const [dialogAddEasyParkVisible, setDialogAddEasyParkVisible] = useState(false as boolean);
  const [dialogAddEasyParkConsented, setDialogAddEasyParkConsented] = useState(false as boolean);
  const [dialogAddEasyParkContracted, setDialogAddEasyParkContracted] = useState(false as boolean);
  const { loading, error, data } = useQuery(gql`
    {
      Vehicle {
        brandName
        fuelType
        licensePlate
        id
        Trips(limit: 15, order_by: { endedAt: desc }) {
          id
          startedAt
          endedAt
          route
          startLocation
          statFuelConsumptionInL
          statTotalDistanceInKm
          statTotalDurationInSec
        }
        name
        modelName
        imageUrl
        Parkings(order_by: { startAt: desc }, limit: 15) {
          costAmount
          costCurrency
          startAt
          status
          stopAt
          Parkinglot {
            city
            location
            name
            owner
            provider
            street
            zoneCode
          }
        }
        VehicleStatuses(limit: 1, order_by: { locationAt: desc }) {
          location
          locationAt
          totalTravelKm
          hvacTempInside
          hvacMode
          evDistanceKm
          gasDistanceAcKm
          gasFullnessPercent
          hvacTempSetting
        }
      }
      Service {
        id
        name
        ServiceChars {
          key
          value
        }
      }
    }
  `);

  return (
    <div>
      {loading && <ProgressBar />}
      <h3>Vehicles</h3>
      {data &&
        data.Vehicle.map((car: any) => {
          const loc = car?.VehicleStatuses[0]?.location;
          const carPosition = L.GeoJSON.coordsToLatLng(loc?.coordinates || [0, 0]);
          const carIcon = <img src={car.imageUrl} style={{ width: "64px", height: "32px" }} />;
          const parkingIcon = (
            <div
              style={{
                width: "32px",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                background: "darkblue",
                border: "2px solid white",
                borderRadius: "5px",
                margin: "auto auto",
                padding: "0px",
              }}
            >
              P
            </div>
          );

          const rental = {
            currTotalKm: car.VehicleStatuses[0]?.totalTravelKm,
            trips: car.Trips?.filter((t) => new Date(t.startedAt).getTime() > new Date("2021-08-03").getTime()).filter((t) => new Date(t.startedAt).getTime() < new Date("2021-08-07").getTime()),
          };

          console.log([
            car.Trips,
            car.Trips?.filter((t) => new Date(t.startedAt).getTime() > new Date("2021-08-03").getTime()),
            car.Trips?.filter((t) => new Date(t.startedAt).getTime() < new Date("2021-08-07T00:00:00").getTime()),
            car.Trips?.filter((t) => new Date(t.startedAt).getTime() > new Date("2021-08-03").getTime()).filter((t) => new Date(t.startedAt).getTime() < new Date("2021-08-07").getTime()),
          ]);

          return (
            <>
              <Card elevation={Elevation.ONE} title={`${car.brandName} ${car.modelName} -  ${car.engine}`}>
                <Link to={`/services/my-vehicles/${car.licensePlate}`}>
                  {car.name || <em>No name</em>}
                  <br />
                  <small>{car.licensePlate}</small>
                </Link>
                <br />
                {car.imageUrl && <img src={car.imageUrl} />}
              </Card>
              <br />
            </>
          );
        })}
      <h3>Services</h3>
      <ul>{data && data.Service.map((s: any) => <li>{s.name}</li>)}</ul>
      <br />
      <Callout title="Lägg till biltjänster">
        Botler fungerar lite annorlunda än vad du nog är van vid.
        <br />
        <br />
        Här ger du access, samtycke och fullmakt till oss på Botler.se att hantera dina bilar och parkeringsbiljetter i andra tjänster.
        <br />
        <br />
        Detta innebär i stort sett att Botler.se - under tiden du ger tillstånd - kommer utge sig för att vara dig mot andra tjänster för att hämta information och utföra köp av parketingsbiljetter.
      </Callout>
      <br />
      <Button icon="add" rightIcon="known-vehicle" intent="primary" onClick={() => setDialogAddEasyParkVisible(true)}>
        Lägg till EasyPark
      </Button>
      <MultistepDialog
        title="Lägg till EasyPark"
        backButtonProps={{ disabled: true }}
        nextButtonProps={{ disabled: !dialogAddEasyParkConsented, text: "Nästa" }}
        finalButtonProps={{ text: "Klart 👌" }}
        isOpen={dialogAddEasyParkVisible}
        onClose={() => setDialogAddEasyParkVisible(false)}
      >
        <DialogStep
          id="add-easypark-consent"
          title="Samtycke"
          nextButtonProps={{ text: "Hej" }}
          panel={
            <Card>
              <h4>Samtycke om datalagring (GDPR)</h4>
              För att kunna tillhandahålla de tjänster som du nu lägger till så kommer Botler vid flera tillfällen läsa, bearbeta och mellanlagra personlig information kopplat till dig och din(a) fordon.
              <br />
              <br />
              Detta gäller för:
              <ul>
                <li>Ditt telefonnummer och access mot EasyPark</li>
                <li>Dina fordons registreringsnummer samt märke/modell/färg </li>
                <li>Dina fordons nuvarande parkeringsbiljett (inkl. plats)</li>
                <li>Dina fordons historiska parkeringsbiljetter (inkl. platser)</li>
              </ul>
              <br />
              Syftet med detta är ge dig en översikt samt för att hantera dina parkeringsbiljetter i enlighet med tjänsten.
              <br />
              <br />
              <em>
                Du kan när som helst avsluta denna Botler-tjänst, utan att det påverkar EasyPark. <br />I detta fall kommer EasyPark-specifik information att raderas ifrån Botler då den inte längre behövs.
              </em>
              <br />
              <br />
              <FormGroup helperText="Lagring av person-/fordonsinformation under tiden du använder tjänsten">
                <Checkbox
                  label="Jag samtycker"
                  style={{ fontWeight: "bold" }}
                  required={true}
                  large={true}
                  defaultChecked={dialogAddEasyParkConsented}
                  onChange={(e: any) => {
                    setDialogAddEasyParkConsented(e.target.checked);
                  }}
                />
              </FormGroup>
            </Card>
          }
        ></DialogStep>
        <DialogStep
          id="add-easypark-contract"
          title="Fullmakt"
          panel={
            <Card>
              <h4>Fullmakt för ombud och biljettköp</h4>
              Botler behöver interagera med EasyPark i ditt namn - likt ett ombud eller en god man.
              <br />
              <br />
              <Callout>
                Detta kontrakt är en fullmakt där <em>Namn</em> ger Botler tillståd att interagera med EasyPark utifrån dennes identitet för dessa specifika ändamål:
                <ul>
                  <li>Logga in i EasyPark tjänsten</li>
                  <li>Hämta, behandla och mellanlagra information om användaren, fordon, platser och tidigare biljettköp</li>
                  <li>Utföra köp av parkeringsbiljetter till fordon som denne begärt</li>
                </ul>
                <FormGroup>
                  <Checkbox
                    label="Signatur"
                    style={{ fontWeight: "bold" }}
                    required={true}
                    large={true}
                    defaultChecked={dialogAddEasyParkContracted}
                    onChange={(e: any) => {
                      setDialogAddEasyParkConsented(e.target.checked);
                    }}
                  />
                </FormGroup>
              </Callout>
              <br />
              <br />
              <em>
                Du kan när som helst avsluta denna Botler-tjänst, utan att det påverkar EasyPark. <br />I det fallet upphör denna fullmakt att gälla.
              </em>
              <br />
              <br />
            </Card>
          }
        ></DialogStep>
        <DialogStep
          id="add-easypark-msisdn"
          title="Mobilnummer"
          panel={
            <Card>
              <h4>Telefonnummer för EasyPark</h4>
              Ange det telefonnummer som du sedan tidigare använder i EasyPark tjänsten. Detta är troligen samma telefonnummer som för den smartphone du har EasyPark appen installerad på.
              <br />
              <br />
              <FormGroup helperText="Mobilnummret som du använder till EasyPark">
                <InputGroup name="name" leftIcon="mobile-phone" type="tel"></InputGroup>
              </FormGroup>
              <br />
              När du går vidare kommer du få ett SMS ifrån EasyPark - till ditt angivna telefonnummer - med en kod som du matar in i sista steget.
            </Card>
          }
        ></DialogStep>
        <DialogStep
          id="add-easypark-msisdn"
          title="SMS kod"
          panel={
            <Card>
              <h4>Ange SMS-koden du snart får</h4>
              Inom kort får du en 4-siffrig kod ifrån EasyPark via SMS. Meddelandet ska ha dagens datum och vara nära i tiden.
              <br />
              <br />
              Ange koden nedan:
              <FormGroup helperText="Den 4-siffriga pin-koden du nu får via SMS">
                <InputGroup name="pin" leftIcon="lock"></InputGroup>
              </FormGroup>
            </Card>
          }
        ></DialogStep>
        <DialogStep
          id="add-easypark-verify"
          title="Verifiera koppling"
          panel={
            <Card>
              <h4>Verifierar koppling till EasyPark</h4>
              Vänta ett ögonblick medan vi loggar in..
              <br />
              <br />
              <Spinner intent="primary" />
            </Card>
          }
        ></DialogStep>
      </MultistepDialog>

      <hr />
      <h3>Debug</h3>
      <pre>{JSON.stringify({ loading, error, data }, null, 2)}</pre>
    </div>
  );
}
