import { Card, Spinner } from "@blueprintjs/core";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import "./App.css";

let setDebugGolbal: any = () => {};

function Login() {
  const [loginUrl, setLoginUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Main>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Content>
          <Card elevation={1} interactive={true}>
            <h3>Invite only</h3>
            <p>
              Botler.se is a private service. If you have gotten an invite, then <br />
              check your e-mail for the link to set up your account.
            </p>
            <p>
              Already have an account? <a href={loginUrl}>Sign in here</a>
            </p>
          </Card>
        </Content>
      )}
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: rgb(243, 243, 243);
  width: 100vw;
  margin: 0px auto;
`;

const Content = styled.div``;

const Logo = styled.div`
  background-image: url("/img/logo-trans.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 13rem;
`;

const UserNav = styled.div`
  margin: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  label {
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 0.8rem;
  }
`;

export default Login;
