import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import React, { createContext, useEffect, useRef, useState } from "react";
import * as apiService from "../services/api-service";
import { useAuth } from "oidc-react";

export interface IUserContext {
  botlerJwt: string | null;
  vehicles: any[] | null;
}

const initialContext: IUserContext = {
  botlerJwt: null,
  vehicles: null,
};
export const UserContext = createContext(initialContext as IUserContext);

export default function (props: any) {
  const [vehicles, setVehicles] = useState([]);
  const [botlerJwt, setBotlerJwt] = useState<string | null>(null);
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const user = useAuth();

  useEffect(() => {
    (async () => {
      console.log("Checking login status..");
      const azureJwt = user.userData?.id_token;
      if (!azureJwt) {
        console.log("Nope!");
      } else {
        console.log("Yes!");
        const botlerJwt = await apiService.tradeAzureForBotlerJWT(azureJwt);
        // const vehicles = await apiService.getMyVehicles(azureJwt);
        setBotlerJwt(botlerJwt);
        // setVehicles(vehicles);
      }
    })();
  }, [user.userData?.id_token]);

  useEffect(() => {
    if (!botlerJwt) return;
    setApolloClient(
      new ApolloClient({
        // uri: window.location.hostname === "localhost" ? "http://localhost:8080/v1/graphql" : "https://dev-graphql.botler.se/v1/graphql",
        uri: "https://graphql.dev.botler.se/v1/graphql",
        cache: new InMemoryCache(),
        headers: {
          Authorization: "Bearer " + botlerJwt,
        },
      })
    );
  }, [botlerJwt]);

  if (!user.userData?.id_token || !apolloClient) return <h1>Loading..</h1>;

  const context: IUserContext = {
    botlerJwt,
    vehicles,
  };

  return (
    <UserContext.Provider value={context}>
      <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
    </UserContext.Provider>
  );
}
