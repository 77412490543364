import { gql, useQuery } from "@apollo/client";
import { ProgressBar } from "@blueprintjs/core";
import "moment/locale/sv";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import turf from "@turf/turf";

var getCentroid = function (arr) {
  return arr.reduce(
    function (x, y) {
      return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
    },
    [0, 0]
  );
};

export default function CarMap(props: any) {
  const { licensePlate, tripIds } = props;
  const { loading, error, data } = useQuery(
    gql`
      query ($licensePlate: String, $tripIds: [uuid!]) {
        Vehicle(where: { licensePlate: { _eq: $licensePlate } }) {
          id
          name
          brandName
          licensePlate
          modelName
          imageUrl

          Trips(where: { id: { _in: $tripIds } }, limit: 15, order_by: { endedAt: desc }) {
            id
            route
            endedAt
            driverId
            startedAt
            totalEndKm
            totalStartKm
            statTotalDistanceInKm
            carRentalId
          }
        }
      }
    `,
    {
      variables: {
        licensePlate,
        tripIds,
      },
    }
  );

  return (
    <div>
      {loading && <ProgressBar />}
      {data &&
        data.Vehicle.map((car: any) => {
          const coords = car?.Trips?.flatMap((t) => t.route.coordinates);
          console.log({ coords });

          const points = turf.polygon(coords);
          const mapCenter = turf.center(points);

          return (
            <div>
              <MapContainer center={[mapCenter.geometry.coordinates[0], mapCenter.geometry.coordinates[1]]} zoom={16} scrollWheelZoom={true} style={{ height: "800px" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {car?.Trips?.map((trip: any) => {
                  return <GeoJSON key={trip.id} data={trip.route} />;
                })}
              </MapContainer>
            </div>
          );
        })}
    </div>
  );
}
