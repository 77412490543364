import * as a from "botler-dto";
import { AuthTradeResponseDTO } from "botler-dto/auth";

const API_ROOT = window.location.hostname === "localhost" ? "http://localhost:4000" : "https://api.dev.botler.se";

export async function request<T>(token: string, method: string, path: string, query: Record<string, string> = {}, body: Record<string, any> = {}, headers: Record<string, string> = {}): Promise<T> {
  const resp = await fetch(
    `${API_ROOT}${path}?${Object.keys(query)
      .reduce((memo, key) => {
        memo.push(`${key}=${encodeURIComponent(query[key])}`);
        return memo;
      }, [] as string[])
      .join("&")}`,
    {
      method,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...(method === "POST" && { "Content-Type": "application/json" }),
        ...headers,
      },
      body: method === "GET" ? undefined : JSON.stringify(body),
    }
  );

  const json = await resp.json();
  return json as T;
}

export async function tradeAzureForBotlerJWT(azureJwt: string): Promise<string> {
  const data = await request<{ botlerJwt: string }>("", "POST", "/auth/trade", undefined, { azureJwt });
  return data.botlerJwt;
}

export async function getMyVehicles(token: string): Promise<any> {
  const myVehiclesResp = await request<any>(token, "GET", "/users/me/vehicles");
  return myVehiclesResp;
}
