import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ContextWrapper from "./data-contexts/wrapper";
import "./index.css";
import App from "./pages/App";
import Login from "./pages/Login";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { AuthProvider } from "oidc-react";

const redirectUri = `${window.location.protocol}//${window.location.host}/`;

const oidcConfig = {
  onSignIn: () => {
    // Redirect?
    window.location.href = "/";
  },
  authority: "https://login.microsoftonline.com/6fbfa384-c2a3-4bf3-8be8-d7d21d341f3b/v2.0",
  clientId: "86f9bd4b-ed3c-4d84-adf2-4dbc63ff0f38",
  scope: "openid profile email",
  autoSignIn: true,
  redirectUri,
  automaticSilentRenew: true,
  silentRedirectUri: redirectUri,
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/login_callback">
          <Login />
        </Route>
        <Route path="/" exact={false}>
          <AuthProvider {...oidcConfig}>
            <ContextWrapper>
              <App />
            </ContextWrapper>
          </AuthProvider>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();

Notification.requestPermission(function (status) {
  console.log("Notification permission status:", status);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
